import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer class="footer_widgets"> 
                <div class="footer_bottom">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="footer_bottom_inner d-flex justify-content-between">
                                    <div class="copyright_right">
                                        <p>Copyrights © 2020 All rights reserved by Ludo Sardar <a target="_blank" href="https://www.Ludo Sardar.com">Ludo Sardar</a></p>
                                    </div>  
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="footer_widget_list footer_list_menu"> 
                                    <div class="footer_menu">
                                        <ul class="d-block text-end">
                                            <li class="d-inline-block"><a href="/PrivacyPolicy">Privacy Policy</a></li>
                                            <li class="d-inline-block"><a href="/TermsAndCond">Terms and Conditions</a></li>
                                            <li class="d-inline-block"><a href="/RefundAndCanc"> Return, Refund &amp; Cancellation </a></li>
                                            <li class="d-inline-block"><a href="/TdsPolicy"> TDS Policy </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
