import React from 'react'

const LandingPage = () => {
    return (
        <>

        <div class="page_wrapper" id='home' >



                 
                <section class="hero_banner_section d-flex align-items-center ">
                    <div class="container">
                        <div class="hero_banner_inner">
                            <div class="row align-items-center">
                                <div class="col-12 col-lg-6">
                                    <div class="hero_content">
                                        <h1 class=" " >Play Ludo Sardar online with your friends</h1>
                                        <p class="">Download The India's Best, 100% Safe & Secure Ludo Sardar App is 100% GST Free, 100% TDS Free</p>
                                        <a class="btn btn-link " href="https://fs11admin.fantasyscore11.com/apkdownload">Download App</a>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6" > 
                                    <div class="hero_position_img">
                                        <img width="785" height="785" src="assets/img/bg/hero-position-img.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div> 
                </section> 


        </div>
        </>
    )
}

export default LandingPage
